import { Input } from '../../Input';
import { FieldProps } from './FieldProps';

export function Phone(props: FieldProps) {
  const {
    className,
    label = 'Телефон',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <Input
      className={className}
      name="phone"
      label={label}
      inputType="phone"
      required={required}
      disabled={isSendingStatus}
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('tel');
        }
      }}
    />
  );
}
