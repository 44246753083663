import { ButtonFunction, ButtonFunctionProps } from '@snack-uikit/button';
import { type LinkProps as NextLinkProps } from 'next/link';
import { MouseEventHandler } from 'react';
import { NextLink } from 'src/components/NextLink';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { ClickAnalyticsType } from 'src/types';

import s from './Link.module.scss';

export type LinkProps = NextLinkProps &
  ButtonFunctionProps & {
    id?: string;
    analyticsOptions?: ClickAnalyticsType;
  };

function Icon({ icon }: { icon?: string | LinkProps['icon'] }) {
  if (typeof icon === 'string') {
    return <img src={icon} alt="" className={s.icon} />;
  }

  return icon;
}

const prevent: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export function Link({
  id,
  analyticsOptions,
  label,
  className = '',
  disabled,
  icon,
  onClick,
  target = '_self',
  size,
  iconPosition,
  href,
  appearance = 'neutral',
  ...rest
}: LinkProps) {
  const { clickAnalytics } = useAnalytics();

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    onClick?.(e);

    if (analyticsOptions) {
      clickAnalytics(analyticsOptions);
    }
  };

  return (
    <NextLink
      {...rest}
      id={id}
      onClick={onLinkClick}
      target={target}
      href={href}
      passHref
      legacyBehavior
      data-click="allclicks"
    >
      <ButtonFunction
        disabled={disabled}
        href={href}
        target={target}
        icon={<Icon icon={icon} />}
        iconPosition={iconPosition}
        size={size}
        label={label}
        onClick={prevent}
        appearance={appearance}
        className={className}
      />
    </NextLink>
  );
}
