import cn from 'classnames';
import React from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { FormBaseProps } from 'src/uikit/Form';

import { Checkbox } from '../Checkbox';
import s from '../Form.module.scss';
import { Input } from '../Input';
import { MultiSelect } from '../MultiSelect';
import { Submit } from '../Submit';
import { TextArea } from '../TextArea';
import { UserAgreementCheckboxes } from '../UserAgreementCheckboxes';

type Props = Pick<
  FormBaseProps,
  | 'fieldsNames'
  | 'short'
  | 'legalEntity'
  | 'multipleComment'
  | 'agreementName'
  | 'withInn'
  | 'withSubscribeForMailing'
  | 'isSubscribeForMailingRequired'
> & {
  sendFormFillAnalytics(name: string): void;
  isPhysic: boolean;
  buttonTitle: string;
  isSendingStatus?: boolean;
};

export function FormDefault({
  fieldsNames,
  short,
  legalEntity,
  multipleComment,
  buttonTitle,
  agreementName,
  sendFormFillAnalytics,
  isPhysic,
  isSubscribeForMailingRequired,
  withSubscribeForMailing,
  withInn,
  isSendingStatus,
}: Props) {
  const { fillFormField } = useAnalytics();
  const layoutType = useLayoutType();

  return (
    <>
      <div className={s.double} data-layout={layoutType}>
        <Input
          name="name"
          label={fieldsNames?.name?.label ?? 'Имя'}
          inputType="text"
          validationType={Input.validations.Name}
          required
          disabled={isSendingStatus}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('name');
            }
          }}
        />
        <Input
          name="phone"
          inputType="phone"
          label={fieldsNames?.phone?.label ?? 'Телефон'}
          required
          disabled={isSendingStatus}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('tel');
            }
          }}
        />
      </div>

      <div
        className={cn({
          [s.double]: withInn,
        })}
        data-layout={layoutType}
      >
        <Input
          name="email"
          inputType="text"
          validationType={Input.validations.Email}
          label={fieldsNames?.email?.label ?? 'Электронная почта'}
          required
          disabled={isSendingStatus}
          className={s.input}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('email');
            }
          }}
        />

        {withInn && (
          <Input
            label="ИНН"
            validationType={Input.validations.Inn}
            name="inn"
            inputType="number"
            className={s.input}
            required
            disabled={isSendingStatus}
            data-qa="section_input"
            onBlur={(event) => {
              if (event.currentTarget.value) {
                fillFormField({
                  formType: 'popup',
                  fieldName: 'ИНН',
                  popupPlace: 'body',
                  buttonId: 'partners-body',
                });
              }
            }}
          />
        )}
      </div>

      {!short && !legalEntity && (
        <Checkbox
          className={s.checkbox}
          required={false}
          disabled={isSendingStatus}
          name="isNaturalPerson"
          value="true"
          label={fieldsNames?.checkbox?.text ?? 'Я физлицо'}
        />
      )}

      {!short && (
        <>
          <div className={s.double} data-layout={layoutType}>
            <Input
              name="company"
              validationType={Input.validations.Company}
              inputType="text"
              label={fieldsNames?.company?.label ?? 'Название компании'}
              required
              disabled={isPhysic || isSendingStatus}
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('company');
                }
              }}
            />
            <Input
              name="position"
              validationType={Input.validations.Position}
              inputType="text"
              label={fieldsNames?.position?.label ?? 'Должность'}
              disabled={isPhysic || isSendingStatus}
              required
              className={s.input}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('position');
                }
              }}
            />
          </div>

          {multipleComment ? (
            <>
              {/*<MultiSelectControl
                options={multipleComment.options}
                control={control}
                name={multipleComment.name}
                label={multipleComment.label}
                errors={errors}
                required
              />*/}
              <MultiSelect
                options={multipleComment.options}
                name={multipleComment.name}
                label={multipleComment.label}
                required
              />
            </>
          ) : (
            <TextArea
              label={fieldsNames?.comment?.label ?? 'Комментарий'}
              name="comment"
              className={s.textarea}
              disabled={isSendingStatus}
              onBlur={(event) => {
                if (event.currentTarget.value) {
                  sendFormFillAnalytics('comment');
                }
              }}
            />
          )}
        </>
      )}

      <UserAgreementCheckboxes
        agreementName={agreementName}
        sendFormFillAnalytics={sendFormFillAnalytics}
        withSubscribeForMailing={withSubscribeForMailing}
        fieldsNames={{
          userAgreement: fieldsNames?.userAgreement,
          checkboxSubscribe: fieldsNames?.checkboxSubscribe,
        }}
        isSubscribeForMailingRequired={isSubscribeForMailingRequired}
      />

      <Submit label={buttonTitle} loading={isSendingStatus} />
    </>
  );
}
