import { TextArea } from '../../TextArea';
import { FieldProps } from './FieldProps';

export function Comment(props: FieldProps) {
  const {
    className,
    label = 'Комментарий',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <TextArea
      className={className}
      name="comment"
      label={label}
      required={required}
      disabled={isSendingStatus}
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('comment');
        }
      }}
    />
  );
}
