import React, { forwardRef } from 'react';
import { InnerForm } from 'src/snack/components/Form/InnerForm';
import { ModalForm } from 'src/snack/components/Form/ModalForm';

import { FormBaseProps } from './types';
export { FormForEnum } from './constants';

export type { FormBaseProps };

// Варианты формы:
// modal - для модальных окон DONE
// inner - для встроенных в страницу DONE
// hero - для встраивания в hero блок WIP

// Конфигурация формы:
// short - короткая форма - DONE
// legalEntity - форма для юр лиц (без чебокса) - DONE

export const Form = forwardRef<HTMLDivElement, FormBaseProps>(
  ({ formVariant, ...props }, ref) => {
    return (
      <>
        {formVariant === 'modal' && (
          <ModalForm formVariant={formVariant} {...props} />
        )}
        {formVariant === 'inner' && (
          <InnerForm formVariant={formVariant} {...props} ref={ref} />
        )}
      </>
    );
  },
);

Form.displayName = 'Form';
