import { useFormContext } from 'react-hook-form';

import { Checkbox } from '../../Checkbox';
import { Input } from '../../Input';
import s from './CompanyPositionOptional.module.scss';
import { FieldProps } from './FieldProps';

export function CompanyPositionOptional(props: FieldProps) {
  const { className, isSendingStatus, sendFormFillAnalytics } = props;

  const { watch } = useFormContext();

  const isPhysic = !!watch('isNaturalPerson', false);

  return (
    <div className={className}>
      <Checkbox
        className={s.checkbox}
        label="Я физлицо"
        required={false}
        disabled={isSendingStatus}
        name="isNaturalPerson"
        value="true"
      />
      <div className={s.double}>
        <Input
          className={s.field}
          name="company"
          label="Название компании"
          validationType={Input.validations.Company}
          inputType="text"
          required
          disabled={isPhysic || isSendingStatus}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('company');
            }
          }}
        />
        <Input
          className={s.field}
          name="position"
          label="Должность"
          validationType={Input.validations.Position}
          inputType="text"
          required
          disabled={isPhysic || isSendingStatus}
          onBlur={(event) => {
            if (event.currentTarget.value) {
              sendFormFillAnalytics('position');
            }
          }}
        />
      </div>
    </div>
  );
}
