export enum ResultCodes {
  EmailAlreadyExist = '03004',
}

export enum FormForEnum {
  OfflineEvent = 'offlineEvent',
  Education = 'education',
  AdditionalMaterials = 'additionalMaterials',
  Registry = 'registry',
  Default = 'default',
}
