import {
  ButtonPromo,
  ButtonPromoProps,
} from '@sbercloud/uikit-product-button-predefined';
import {
  ButtonFilled,
  ButtonFilledProps,
  ButtonFunctionProps,
  ButtonOutline,
  ButtonOutlineProps as SnackButtonOutlineProps,
} from '@snack-uikit/button';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Link } from 'src/snack/components/Link';
import { ClickAnalyticsType } from 'src/types';

type ButtonBaseProps = {
  id?: string;
  size?: 'standard' | 'big';
  analyticsOptions: ClickAnalyticsType;
  onClick?(): void;
  variant?: 'primary' | 'outline' | 'tertiary';
};

type ButtonPrimaryProps = Omit<ButtonFilledProps, 'size'> & ButtonBaseProps;

type ButtonOutlineProps = Omit<SnackButtonOutlineProps, 'size'> &
  ButtonBaseProps;

type ButtonTertiaryProps = Omit<ButtonPromoProps, 'size'> & ButtonBaseProps;

type ButtonLinkProps = Omit<ButtonFunctionProps, 'size'> & ButtonBaseProps;

type ButtonAppearanceProps =
  | ButtonPrimaryProps
  | ButtonOutlineProps
  | ButtonTertiaryProps
  | ButtonLinkProps;

export type ButtonProps = Omit<
  ButtonBaseProps & ButtonAppearanceProps,
  'appearance'
>;

const mapSizeToButtonSize = (
  size: ButtonProps['size'],
): ButtonFilledProps['size'] => (size === 'big' ? 'l' : 'm');

export function Button({
  id,
  variant = 'primary',
  size = 'standard',
  onClick,
  type,
  href,
  className,
  target = '_self',
  analyticsOptions,
  label,
  disabled,
  ...rest
}: ButtonProps) {
  const { clickAnalytics } = useAnalytics();

  const onButtonClick = () => {
    if (!disabled)
      try {
        clickAnalytics(analyticsOptions);

        onClick && onClick();
      } catch {
        // noop
      }
  };

  const commonProps = {
    label: label,
    onClick: onButtonClick,
    size: mapSizeToButtonSize(size),
    className,
    disabled,
    ...rest,
  };

  const buttonProps = {
    id,
    ...commonProps,
    type,
  };

  if (href) {
    return (
      <Link
        href={href}
        analyticsOptions={analyticsOptions}
        id="temporary"
        target={target}
        {...commonProps}
        {...rest}
      />
    );
  }

  switch (variant) {
    case 'outline': {
      return <ButtonOutline {...buttonProps} appearance="neutral" />;
    }
    case 'tertiary': {
      return <ButtonPromo {...buttonProps} appearance="tertiary" />;
    }
    case 'primary':
    default: {
      return <ButtonFilled {...buttonProps} />;
    }
  }
}
