import { FieldType } from 'src/types/backendContent/RegistryForm';

import { Comment } from './Comment';
import { Company } from './Company';
import { CompanyPositionOptional } from './CompanyPositionOptional';
import { Email } from './Email';
import { FieldProps } from './FieldProps';
import { FirstName } from './FirstName';
import { Inn } from './Inn';
import { LastName } from './LastName';
import { Name } from './Name';
import { Phone } from './Phone';
import { Position } from './Position';

type FieldRegistryField = (props: FieldProps) => JSX.Element;

export const FieldsRegistry: Record<FieldType, FieldRegistryField> = {
  comment: Comment,
  company: Company,
  companyPositionOptional: CompanyPositionOptional,
  email: Email,
  firstName: FirstName,
  inn: Inn,
  lastName: LastName,
  name: Name,
  phone: Phone,
  position: Position,
};
