import { Input } from '../../Input';
import { FieldProps } from './FieldProps';

export function Name(props: FieldProps) {
  const {
    className,
    label = 'Имя',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <Input
      className={className}
      name="name"
      label={label}
      inputType="text"
      validationType={Input.validations.Name}
      required={required}
      disabled={isSendingStatus}
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('name');
        }
      }}
    />
  );
}
