import {
  FieldPhone,
  usePredefinedPhoneMasks,
} from '@sbercloud/uikit-product-fields-predefined';
import { FieldText, FieldTextProps } from '@snack-uikit/fields';
import { FocusEvent, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { valSchema } from 'src/utils/valSchema';

import { PHONE_VALIDATION_TYPE, ValidationType } from './constants';

type InputBase = Pick<
  FieldTextProps,
  | 'value'
  | 'onChange'
  | 'onBlur'
  | 'className'
  | 'required'
  | 'label'
  | 'disabled'
  | 'placeholder'
> & {
  name: string;
  required: boolean;
};

type PhoneInput = InputBase & {
  inputType: 'phone';
  validationType?: undefined;
};

type NumberInput = InputBase & {
  inputType: 'number';
  validationType: ValidationType;
};

type TextInput = InputBase & {
  inputType: 'text';
  validationType: ValidationType;
};

export type InputProps = PhoneInput | TextInput | NumberInput;

const getSchema = (
  validationType: ValidationType | typeof PHONE_VALIDATION_TYPE,
  required: boolean,
  disabled?: boolean,
) => {
  const schema = valSchema[validationType];
  return {
    ...schema,
    required: !disabled && required ? schema?.required : false,
  };
};

function InputPhone({
  label,
  required,
  name,
  disabled,
  className = '',
  getOnChangeInputHandler,
  getOnBlurInputHandler,
  ...rest
}: Omit<PhoneInput, 'inputType' | 'placeholder'> & {
  getOnChangeInputHandler(
    onChange: (value: string) => void,
  ): (value: string) => void;
  getOnBlurInputHandler(
    onBlurInput: (event: FocusEvent<HTMLInputElement>) => void,
  ): (event: FocusEvent<HTMLInputElement>) => void;
}) {
  const [russianPhoneMask] = usePredefinedPhoneMasks();

  return (
    <Controller
      name={name}
      rules={getSchema(PHONE_VALIDATION_TYPE, required, disabled)}
      render={({ field, fieldState }) => (
        <FieldPhone
          size="l"
          label={label}
          required={required}
          disabled={disabled}
          className={className}
          {...field}
          {...rest}
          options={[russianPhoneMask]}
          onBlur={getOnBlurInputHandler(field.onBlur)}
          onChange={getOnChangeInputHandler(field.onChange)}
          error={fieldState.error?.message}
          validationState={
            fieldState.isDirty && !fieldState.error ? 'success' : undefined
          }
          data-test-id="phone-input"
          data-click="funnel-form-input"
        />
      )}
    />
  );
}

export function Input({
  label,
  required,
  validationType,
  name,
  disabled,
  inputType,
  placeholder,
  className = '',
  onBlur,
  onChange,
  ...rest
}: InputProps) {
  const { funnelFormInput } = useAnalytics();

  const getOnChangeInputHandler = useCallback(
    (handleChange: (val: string) => void) => (value: string) => {
      let val = value;

      if (inputType === 'number') {
        val = value.replace(/(?!\d).*/g, '');
      }

      handleChange(val);
      onChange?.(val);
    },
    [inputType, onChange],
  );

  const getOnBlurInputHandler = useCallback(
    (onBlurInput: (event: FocusEvent<HTMLInputElement>) => void) =>
      (event: FocusEvent<HTMLInputElement>) => {
        onBlurInput(event);
        onBlur?.(event);
      },
    [onBlur],
  );

  if (inputType === 'phone') {
    return (
      <InputPhone
        name={name}
        label={label}
        required={required}
        disabled={disabled}
        getOnBlurInputHandler={getOnBlurInputHandler}
        getOnChangeInputHandler={getOnChangeInputHandler}
      />
    );
  }

  return (
    <div onClick={() => funnelFormInput()} className={className}>
      <Controller
        name={name}
        rules={getSchema(validationType as ValidationType, required, disabled)}
        render={({ field, fieldState }) => (
          <FieldText
            id={name}
            required={required}
            disabled={disabled}
            label={label}
            placeholder={placeholder}
            size="l"
            {...field}
            {...rest}
            onBlur={getOnBlurInputHandler(field.onBlur)}
            onChange={getOnChangeInputHandler(field.onChange)}
            error={fieldState.error?.message}
            validationState={
              fieldState.isDirty && !fieldState.error ? 'success' : undefined
            }
            data-click="funnel-form-input"
            data-test-id={inputType === 'number' ? 'number-input' : 'input'}
          />
        )}
      />
    </div>
  );
}

Input.validations = ValidationType;
