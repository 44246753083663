import { FormBase } from 'src/snack/components/Form/FormBase';
import { FormBaseProps } from 'src/uikit/Form';

import { Modal } from '../../Modal';

export function ModalForm({
  modalClose,
  productTitle,
  eventCategory,
  ...props
}: FormBaseProps) {
  return (
    <>
      {modalClose && (
        <Modal open={true} onClose={modalClose}>
          <FormBase
            // FIXME: почему хардкод? В props так же есть dataLayer
            dataLayer={{
              event: 'custom_event',
              event_category: eventCategory || 'consultation LeadForm',
              event_action: 'formSuccess',
              event_label: productTitle ? productTitle : 'mainPage',
            }}
            {...props}
            successfulSubmissonFormAnalytics={
              props.successfulSubmissonFormAnalytics && {
                zoneclick:
                  props.successfulSubmissonFormAnalytics.zoneclick || 'P',
                formname: props.successfulSubmissonFormAnalytics?.formname,
              }
            }
            allFormsSentIsPopup
          />
        </Modal>
      )}
    </>
  );
}
