import { FormField } from 'src/types/backendContent/RegistryForm';
import { FormBaseProps } from 'src/uikit/Form';

import { MultiSelect } from '../MultiSelect';
import { Submit } from '../Submit';
import { UserAgreementCheckboxes } from '../UserAgreementCheckboxes';
import { FieldsRegistry } from './FieldsRegistry';
import s from './FormRegistry.module.scss';

type FormRegistryProps = Pick<
  FormBaseProps,
  'agreementName' | 'buttonTitle' | 'multipleComment'
> & {
  fields?: FormField[];
  isSendingStatus?: boolean;
  sendFormFillAnalytics(name: string): void;
};

export function FormRegistry(props: FormRegistryProps) {
  const {
    fields = [],
    isSendingStatus,
    agreementName,
    buttonTitle,
    multipleComment,
    sendFormFillAnalytics,
  } = props;

  return (
    <>
      <div className={s.root}>
        {fields?.map((field) => {
          const Component = FieldsRegistry[field.type];

          if (multipleComment && field.type === 'comment')
            return (
              <MultiSelect
                key={field.type}
                classNames={{ wrapper: s.full }}
                options={multipleComment.options}
                name={multipleComment.name}
                label={multipleComment.label}
                required
              />
            );

          return (
            <Component
              key={field.type}
              className={field.size === 'full' ? s.full : s.half}
              label={field.label}
              required={field.required}
              isSendingStatus={isSendingStatus}
              sendFormFillAnalytics={sendFormFillAnalytics}
            />
          );
        })}
      </div>
      <UserAgreementCheckboxes
        agreementName={agreementName}
        sendFormFillAnalytics={sendFormFillAnalytics}
        disabled={isSendingStatus}
      />
      <Submit label={buttonTitle} loading={isSendingStatus} />
    </>
  );
}
