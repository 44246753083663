// router.query может вернуть string | string[] | undefined
// но как правило нужно что бы он вернул именно строку
export const getStringFromRouterQuery = (
  urlQuery: string | string[] | null | undefined,
): string => {
  if (!urlQuery) return '';
  if (typeof urlQuery === 'string') return urlQuery;
  if (urlQuery[0]) return urlQuery[0];
  return '';
};
