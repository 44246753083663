import {
  AdaptiveFieldSelect,
  BaseOptionProps,
  MobileFieldSelectMultipleProps,
} from '@sbercloud/uikit-product-mobile-fields';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Option } from 'react-multi-select-component';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { SolutionsProps } from 'src/uikit/Form/types';
import { valSchema } from 'src/utils/valSchema';

import s from './MultiSelect.module.scss';

type MultiSelectProps = Pick<
  MobileFieldSelectMultipleProps,
  'required' | 'disabled'
> & {
  options: Option[];
  name: string;
  required?: boolean;
  label: string;
  classNames?: {
    wrapper?: string;
  };
};

export function MultiSelect({
  options,
  name,
  label,
  classNames,
  required = false,
  disabled,
  ...rest
}: MultiSelectProps) {
  const { clickAnalytics } = useAnalytics();

  const layoutType = useLayoutType();

  const [labels, setLabels] = useState<Option[]>([]);

  const opts = useMemo<BaseOptionProps[]>(() => {
    return options.map((opt) => ({
      value: opt.value,
      option: opt.label,
      disabled: opt.disabled,
    }));
  }, [options]);

  const getOnChangeHandler =
    (onChange: (val: SolutionsProps[]) => void) =>
    (selectValue: NonNullable<MobileFieldSelectMultipleProps['value']>) => {
      const value = options.filter((opt) => selectValue.includes(opt.value));
      onChange(value);

      if (value.length === 0) {
        if (labels.length > 1) {
          clickAnalytics({
            action: 'click',
            clickZone: 'body',
            clickElement: 'filter-select',
            clickContent: 'clear-all-filters',
            uniqueId: `multiselect-${name}`,
            transitionType: 'inside-link',
          });
        } else {
          clickAnalytics({
            action: 'click',
            clickZone: 'body',
            clickElement: 'filter-select',
            clickContent: labels[0].label,
            uniqueId: `multiselect-${name}`,
            transitionType: 'inside-link',
          });
        }
        setLabels([]);
        return;
      }

      if (value.length > labels.length) {
        setLabels(value);
        clickAnalytics({
          action: 'click',
          clickZone: 'body',
          clickElement: 'filter-select',
          clickContent: value[value.length - 1].label,
          uniqueId: `multiselect-${name}`,
          transitionType: 'inside-link',
        });
      } else {
        setLabels(value);
        const removedOption = labels.filter(
          (label) => !value.map((i) => i.label).includes(label.label),
        );
        if (removedOption.length === 0) return;

        clickAnalytics({
          action: 'click',
          clickZone: 'body',
          clickElement: 'filter-select',
          clickContent: removedOption[0].label,
          uniqueId: `multiselect-${name}`,
          transitionType: 'inside-link',
        });
      }
    };

  return (
    <Controller
      name={name}
      rules={{
        required: required ? valSchema.default.required.message : undefined,
      }}
      render={({ field, fieldState }) => (
        <div className={cn(s.multiSelect, classNames?.wrapper)} {...rest}>
          <AdaptiveFieldSelect
            layoutType={layoutType}
            selection="multiple"
            size="l"
            options={opts}
            value={(field.value as SolutionsProps[])?.map(({ value }) => value)}
            required={required}
            disabled={disabled}
            searchable={false}
            label={label}
            error={fieldState.error?.message}
            onChange={getOnChangeHandler(field.onChange)}
          />
        </div>
      )}
    />
  );
}
