import { Dispatch, SetStateAction } from 'react';
import { FormStatus } from 'src/types';

import { FormNotice } from './FormNotice';
import { NoticeType } from './types';

export type FormNoticeBlockProps = {
  formStatus: FormStatus;
  successNotice: NoticeType;
  errorNotice: NoticeType;
  setFormStatus: Dispatch<SetStateAction<FormStatus>>;
  analyticsButtonId: string;
  formVariant: 'inner' | 'modal' | 'hero';
  showNotice: boolean;
};

export function FormNoticeBlock({
  formStatus,
  analyticsButtonId,
  successNotice,
  errorNotice,
  setFormStatus,
  formVariant,
  showNotice,
}: FormNoticeBlockProps) {
  const notice = formStatus === 'success' ? successNotice : errorNotice;

  if ((formStatus !== 'success' && formStatus !== 'error') || !showNotice)
    return null;

  const isModalVariant = formVariant === 'modal';

  return (
    <>
      <FormNotice
        title={notice?.title}
        buttonText={notice?.buttonTitle || ''}
        isModal={false}
        analyticsButtonId={analyticsButtonId}
        isError={formStatus === 'error'}
        {...(isModalVariant
          ? {
              onButtonClick: () => setFormStatus('idle'),
            }
          : {})}
      />
    </>
  );
}
