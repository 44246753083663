import { Input } from '../../Input';
import { FieldProps } from './FieldProps';

export function Company(props: FieldProps) {
  const {
    className,
    label = 'Название компании',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <Input
      className={className}
      name="company"
      label={label}
      validationType={Input.validations.Company}
      inputType="text"
      required={required}
      disabled={isSendingStatus}
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('company');
        }
      }}
    />
  );
}
