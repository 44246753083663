import { Input } from '../../Input';
import { FieldProps } from './FieldProps';

export function Email(props: FieldProps) {
  const {
    className,
    label = 'Электронная почта',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <Input
      className={className}
      name="email"
      label={label}
      inputType="text"
      validationType={Input.validations.Email}
      required={required}
      disabled={isSendingStatus}
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('email');
        }
      }}
    />
  );
}
