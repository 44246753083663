import { FormBaseProps } from 'src/uikit/Form';

import s from '../Form.module.scss';
import { Input } from '../Input';
import { Submit } from '../Submit';
import { UserAgreementCheckboxes } from '../UserAgreementCheckboxes';

type FormAdditionalMaterialsProps = Pick<
  FormBaseProps,
  'fieldsNames' | 'withSubscribeForMailing' | 'buttonTitle' | 'agreementName'
> & {
  isSendingStatus?: boolean;
  sendFormFillAnalytics(field: string): void;
};

export function FormAdditionalMaterials({
  fieldsNames,
  agreementName,
  buttonTitle,
  withSubscribeForMailing,
  sendFormFillAnalytics,
  isSendingStatus,
}: FormAdditionalMaterialsProps) {
  return (
    <>
      <Input
        name="email"
        inputType="text"
        validationType={Input.validations.Email}
        label={fieldsNames?.email?.label ?? 'Электронная почта'}
        required
        disabled={isSendingStatus}
        className={s.input}
        onBlur={(event) => {
          if (event.currentTarget.value) {
            sendFormFillAnalytics('email');
          }
        }}
      />

      <UserAgreementCheckboxes
        agreementName={agreementName}
        sendFormFillAnalytics={sendFormFillAnalytics}
        withSubscribeForMailing={withSubscribeForMailing}
        fieldsNames={{
          userAgreement: fieldsNames?.userAgreement,
          checkboxSubscribe: fieldsNames?.checkboxSubscribe,
        }}
        isSubscribeForMailingRequired={true}
        onlyMail={true}
        disabled={isSendingStatus}
      />

      {buttonTitle && <Submit label={buttonTitle} loading={isSendingStatus} />}
    </>
  );
}
