import { AdaptiveModal } from '@sbercloud/uikit-product-mobile-modal';
import { InfoBlock } from '@snack-uikit/info-block';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useLayoutType } from 'src/hooks/useLayoutType';

import s from './FormNotice.module.scss';

type VariantProps = {
  className?: string;
  title: string;

  isError?: boolean;

  isModal: boolean;
  onButtonClick?(): void;
};

type WithButton = {
  buttonText: string;
  analyticsButtonId: string;
};

type WithoutButtons = {
  buttonText?: undefined;
  analyticsButtonId?: undefined;
};

type ButtonVariant = WithButton | WithoutButtons;

type InnerProps = {
  isModal: false;
  onClose?(): undefined;
};

type ModalProps = {
  isModal: true;
  onClose(): void;
};

type FormNoticeProps = VariantProps & (InnerProps | ModalProps) & ButtonVariant;

export function FormNotice({
  title,
  analyticsButtonId,
  buttonText = '',
  className = '',
  isModal,
  isError = false,
  onButtonClick,
  onClose,
}: FormNoticeProps) {
  const router = useRouter();

  const layoutType = useLayoutType();

  const { clickAnalytics } = useAnalytics();

  const [open, setOpen] = useState(true);

  const handleCloseModal = () => {
    setOpen(false);
    onClose?.();
  };

  const handleButtonClick = () => {
    clickAnalytics({
      action: 'click',
      clickZone: 'body',
      clickElement: 'button',
      clickContent: buttonText,
      uniqueId: `notice-modal-button-${analyticsButtonId}`,
      transitionType: 'inside-link',
    });

    if (isError) {
      globalThis?.location.reload();
      return;
    }

    if (onButtonClick) {
      onButtonClick();
    } else {
      router.push('/');
    }
  };

  if (isModal) {
    return (
      <AdaptiveModal
        open={open}
        onClose={handleCloseModal}
        title={title}
        layoutType={layoutType}
        className={className}
        approveButton={{
          label: buttonText,
          onClick: handleButtonClick,
        }}
      />
    );
  }

  return (
    <InfoBlock
      title={title}
      size="l"
      className={cn(s.root, className)}
      data-layout-type={layoutType}
      footer={
        buttonText ? (
          <InfoBlock.Footer
            primaryButton={{
              label: buttonText,
              onClick: handleButtonClick,
              'data-test-id': 'notice-button',
            }}
          />
        ) : undefined
      }
    />
  );
}
