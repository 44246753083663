export enum ValidationType {
  Name = 'name',
  Surname = 'surname',
  SecondName = 'secondName',
  Address = 'address',
  Email = 'email',
  Company = 'company',
  Inn = 'inn',
  CorpEmail = 'corpEmail',
  Position = 'position',
  Product = 'product',
  Site = 'site',
  Default = 'default',
  Kpp = 'kpp',
  Passport = 'passport',
  PhoneWithOutMask = 'phoneWithOutMask',
}

export const PHONE_VALIDATION_TYPE = 'phone';
