import cn from 'classnames';
import { forwardRef } from 'react';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { FormBaseProps } from 'src/uikit/Form/types';

import s from '../Form.module.scss';
import { FormBase } from '../FormBase';

export const InnerForm = forwardRef<HTMLElement, FormBaseProps>(
  ({ id, classNames = {}, ...props }, ref) => {
    const layoutType = useLayoutType();

    return (
      <section
        ref={ref}
        className={cn(s.inner, classNames?.inner)}
        data-layout-type={layoutType}
        id={id}
      >
        <div className={s.wrapper} data-layout-type={layoutType}>
          <FormBase
            {...props}
            successfulSubmissonFormAnalytics={
              props.successfulSubmissonFormAnalytics && {
                zoneclick:
                  props.successfulSubmissonFormAnalytics.zoneclick || 'B',
                formname: props.successfulSubmissonFormAnalytics?.formname,
              }
            }
          />
        </div>
      </section>
    );
  },
);

InnerForm.displayName = 'InnerForm';
