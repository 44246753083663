import { FieldTextArea, FieldTextAreaProps } from '@snack-uikit/fields';
import cn from 'classnames';
import { Controller } from 'react-hook-form';
import { useAnalytics } from 'src/hooks/useAnalytics';

import { MAX_TEXTAREA_CHARACTERS } from './constants';
import s from './TextArea.module.scss';

type TextAreaProps = Pick<
  FieldTextAreaProps,
  | 'label'
  | 'className'
  | 'required'
  | 'onBlur'
  | 'onChange'
  | 'disabled'
  | 'maxLength'
  | 'maxRows'
> & {
  name: string;
};

export function TextArea({
  label,
  name,
  required,
  disabled,
  className,
  onBlur,
  maxLength,
  onChange,
  maxRows,
  ...rest
}: TextAreaProps) {
  const { funnelFormInput } = useAnalytics();

  const limitCharacters = maxLength ?? MAX_TEXTAREA_CHARACTERS;

  return (
    <div className={cn(s.root, className)} onClick={() => funnelFormInput()}>
      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <FieldTextArea
            id={name}
            disabled={disabled}
            maxLength={limitCharacters}
            required={required}
            label={label}
            maxRows={maxRows}
            minRows={1}
            allowMoreThanMaxLength={false}
            {...rest}
            {...field}
            onChange={(value) => {
              field.onChange(value);
              onChange?.(value);
            }}
            onBlur={(e) => {
              field.onBlur();
              onBlur?.(e);
            }}
            size="l"
            error={
              fieldState.error
                ? fieldState.error?.message ?? 'Обязательное поле'
                : undefined
            }
            data-test-id="textarea"
            data-click="funnel-form-input"
          />
        )}
      />
    </div>
  );
}
