import { Input } from '../../Input';
import { FieldProps } from './FieldProps';

export function Inn(props: FieldProps) {
  const {
    className,
    label = 'ИНН',
    required = false,
    isSendingStatus,
    sendFormFillAnalytics,
  } = props;

  return (
    <Input
      className={className}
      name="inn"
      label={label}
      validationType={Input.validations.Inn}
      inputType="number"
      required={required}
      disabled={isSendingStatus}
      data-qa="section_input"
      onBlur={(event) => {
        if (event.currentTarget.value) {
          sendFormFillAnalytics('ИНН');
        }
      }}
    />
  );
}
