import {
  LAYOUT_TYPE,
  LayoutType,
  MatchMedia,
  useMatchMedia,
} from '@sbercloud/uikit-product-utils';
import { useEffect, useState } from 'react';

const matchMediaToLayoutType = ({
  isMobile,
  isSmallDesktop,
  isTablet,
}: MatchMedia) => {
  if (isMobile) {
    return LAYOUT_TYPE.Mobile;
  }

  if (isTablet) {
    return LAYOUT_TYPE.Tablet;
  }

  if (isSmallDesktop) {
    return LAYOUT_TYPE.DesktopSmall;
  }

  return LAYOUT_TYPE.Desktop;
};

export function useLayoutType() {
  const matchMedia = useMatchMedia();
  const [layoutType, setLayoutType] = useState<LayoutType>('desktop');

  useEffect(() => {
    if (matchMedia) {
      setLayoutType(matchMediaToLayoutType(matchMedia));
    }
  }, [matchMedia]);

  return layoutType;
}
