import {
  ModalProps as SnackModalProps,
  useAdaptiveModalCustom,
} from '@sbercloud/uikit-product-mobile-modal';
import { ContentAlign } from '@sbercloud/uikit-product-mobile-modal/dist/esm/types';
import { ReactNode } from 'react';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { useLockBodyScroll } from 'src/hooks/useLockBodyScroll';

type ModalProps = Pick<SnackModalProps, 'open' | 'onClose'> & {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  size?: 's' | 'm' | 'l' | 'full' | 'auto';
  align?: ContentAlign;
};

export function Modal({
  open,
  onClose,
  title,
  subtitle,
  size,
  children,
  align = 'center',
}: ModalProps) {
  const layoutType = useLayoutType();
  const AdaptiveModal = useAdaptiveModalCustom({ layoutType });

  useLockBodyScroll();

  return (
    <AdaptiveModal
      open={open}
      onClose={onClose}
      mode="regular"
      // need to fix typings in useAdaptiveModalCustom
      // @ts-ignore
      size={size ?? (layoutType === 'mobile' ? 'full' : 'm')}
    >
      <AdaptiveModal.Header title={title} subtitle={subtitle} align={align} />

      <AdaptiveModal.Body content={children} />
    </AdaptiveModal>
  );
}
