import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { useSearchParams } from 'src/contexts/SearchParams/useSearchParams';

export const useAsPath = (): string => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const asPath = useMemo(() => {
    const queryString =
      searchParams && Array.from(searchParams).length > 0
        ? `?${searchParams.toString()}`
        : '';

    return pathname + queryString;
  }, [pathname, searchParams]);

  return asPath;
};
