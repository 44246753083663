import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';
import { useSearchParams } from 'src/contexts/SearchParams/useSearchParams';
import { backendHost } from 'src/global';
import { DataFromFormInputs, FormStatus } from 'src/types';
import { getStringFromRouterQuery } from 'src/utils/getStringFromRouterQuery';

import { AnalyticsFormType, useAnalytics } from './useAnalytics';
import { useGetUserMetaData } from './useGetUserMetaData';

type BackendPatchInput = `/${string}`;

interface ResFormData {
  resultCode: string;
  success: boolean;
  data?: unknown;
}

interface ResFormDataOnSuccess extends ResFormData {
  resultCode: '006' | '004' | '001';
  success: true;
}

interface ResFormDataOnError extends ResFormData {
  resultCode: string;
  success: false;
}

interface FormAfterAdapter {
  formStatus: FormStatus;
  formMessage: string;
  resData?: unknown;
  resultCode: string;
}

const MockFormSuccessCodesAndMessagesRu = [
  {
    code: '006',
    message: 'Форма успешно зарегистрирована',
  },
  {
    code: '004',
    message: 'Данные формы успешно обновлены',
  },
  {
    code: '001',
    message: 'Запрос успешно завершился',
  },
];

const MockFormSuccessCodesAndMessagesEn = [
  {
    code: '006',
    message: 'The form has been registered',
  },
  {
    code: '004',
    message: 'The form data has been updated',
  },
  {
    code: '001',
    message: 'The form has been submitted',
  },
];

const formCodeToMessageSuccess = (resultCode: string) => {
  const itemWithThisCodeRu = MockFormSuccessCodesAndMessagesRu.find(
    (item) => item.code === resultCode,
  );
  if (itemWithThisCodeRu) return itemWithThisCodeRu.message;

  return 'Запрос успешно завершился';
};

// вики с кодами ответов форм: https://wiki.sbercloud.tech/pages/viewpage.action?pageId=92524318
const MockFormErrorsCodesAndMessagesRu = [
  // 01 - неизвестные ошибки
  {
    code: '01001',
    message: 'Неизвестная ошибка приложения',
  },
  {
    code: '01002',
    message: 'Неизвестная ошибка сервиса',
  },
  {
    code: '01003',
    message: 'Неизвестная ошибка валидации',
  },
  // 02 - ошибки валидации
  {
    code: '13001',
    message: 'Проблемы с авторизацией',
  },
  {
    code: '12001',
    message: 'Проблемы с полем Пользовательское соглашение',
  },
  {
    code: '12002',
    message: 'Проблемы с полем Имя',
  },
  {
    code: '12003',
    message: 'Проблемы с полем Фамилия',
  },
  {
    code: '12004',
    message: 'Проблемы с полем Отчество',
  },
  {
    code: '12005',
    message: 'Проблемы с полем Почта',
  },
  {
    code: '12006',
    message: 'Проблемы с полем Телефон',
  },
  {
    code: '12007',
    message: 'Проблемы с полем ИНН',
  },
  {
    code: '12008',
    message: 'Проблемы с полем ИНН',
  },
  {
    code: '12009',
    message: 'Проблемы с полем Название компании',
  },
  {
    code: '12010',
    message: 'Проблемы с полем Комментарий',
  },
  {
    code: '12011',
    message: 'Проблемы с полем Название продукта',
  },
  {
    code: '12012',
    message: 'Проблемы с полем Продукт',
  },
  {
    code: '12013',
    message: 'Проблемы с полем Позиция',
  },
  {
    code: '12014',
    message: 'Проблемы с полем Название вакансии',
  },
  {
    code: '12015',
    message: 'Проблемы с полем Вопрос',
  },
  {
    code: '12016',
    message: 'Проблемы с полем Отрасль',
  },
  {
    code: '12017',
    message: 'Проблемы с полем Выбранные категории',
  },
  {
    code: '12018',
    message: 'Проблемы с полем Территориальное подразделение',
  },
  {
    code: '12019',
    message: 'Проблемы с полем Область деятельности организации',
  },
  {
    code: '12020',
    message:
      'Неправильно выбрана область деятельности организации для выбранного территориального подразделения',
  },
  {
    code: '12021',
    message: 'Отсутствие пользовательского соглашения в договоре',
  },
  {
    code: '13020',
    message: 'Присутствуют нарушения в соответствии с соглашением',
  },
  {
    code: '12022',
    message: 'Проблемы с полем Файл',
  },
  {
    code: '12036',
    message: 'Проблемы с полем Дата',
  },
  {
    code: '12037',
    message: 'Проблемы с полем Заказчик проводит конкурс/тендер',
  },
  {
    code: '12038',
    message: 'Проблемы с полем Ссылка на тендер',
  },
  {
    code: '12039',
    message: 'Проблемы с полем Тендер по ФЗ 223, 44 или коммерческий',
  },
  {
    code: '13015',
    message: 'Проблемы с полем Категория персональных данных',
  },
  {
    code: '13016',
    message: 'Проблемы с полем Категория субъектов',
  },
  {
    code: '13017',
    message: 'Проблемы с полем Количество субъектов',
  },
  {
    code: '13018',
    message: 'Проблемы с полем Тип актуальных угроз',
  },
  {
    code: '13019',
    message: 'Проблемы с полем Модель предоставления облачных услуг',
  },
  {
    code: '13021',
    message: 'Пожалуйста, введите вашу рабочую почту на домене cloud.ru',
  },

  // 03 - логические ошибки сервиса
  {
    code: '03010',
    message: 'Указанная почта не найдена',
  },
  {
    code: '03002', // Сущность подписки
    message: 'Указанная почта уже существует',
  },
  {
    code: '03004', // Сущность вебинаров
    message: 'Вы уже зарегистрированы на данное мероприятие',
  },
  {
    code: '03009',
    message: 'Превышен лимит заявок на отзыв ПД',
  },
  {
    code: '13022',
    message: 'Почта уже зарегистрирована на вебинар',
  },
  {
    code: '13023',
    message: 'Почта или телефон уже зарегистрированы',
  },
  // 04
  {
    code: '04001',
    message: 'Что-то не так с вашим запросом',
  },
  {
    code: '04002',
    message: 'Что-то пошло не так',
  },
  {
    code: '04013',
    message: 'Что-то пошло не так',
  },
  {
    code: '14001',
    message: 'Что-то пошло не так',
  },
  {
    code: '04005',
    message: 'Ошибка авторизации',
  },
  {
    code: '04006',
    message: 'Ошибка авторизации',
  },
  {
    code: '04014',
    message: 'Что-то не так с вашим запросом',
  },
];

const MockFormErrorsCodesAndMessagesEn = [
  // 01 - неизвестные ошибки
  {
    code: '01001',
    message: 'Unknown application error',
  },
  {
    code: '01002',
    message: 'Unknown service error',
  },
  {
    code: '01003',
    message: 'Unknown validation error',
  },
  // 02 - ошибки валидации
  {
    code: '13001',
    message: 'Problems with authorization',
  },
  {
    code: '12001',
    message: 'Problems with field User agreement',
  },
  {
    code: '12002',
    message: 'Problems with field Name',
  },
  {
    code: '12003',
    message: 'Problems with field Surname',
  },
  {
    code: '12004',
    message: 'Problems with field Middle name',
  },
  {
    code: '12005',
    message: 'Problems with field Email',
  },
  {
    code: '12006',
    message: 'Problems with field Phone',
  },
  {
    code: '12007',
    message: 'Problems with field Inn',
  },
  {
    code: '12008',
    message: 'Problems with field Inn',
  },
  {
    code: '12009',
    message: 'Problems with field Company',
  },
  {
    code: '12010',
    message: 'Problems with field Comment',
  },
  {
    code: '12011',
    message: 'Problems with field Product name',
  },
  {
    code: '12012',
    message: 'Problems with field Product',
  },
  {
    code: '12013',
    message: 'Problems with field Position',
  },
  {
    code: '12014',
    message: 'Problems with field Vacancy name',
  },
  {
    code: '12015',
    message: 'Problems with field Question',
  },
  {
    code: '12016',
    message: 'Problems with field of activity',
  },
  {
    code: '12017',
    message: 'Problems with field Checked categories',
  },
  {
    code: '12018',
    message: 'Problems with field Territorial subdivision',
  },
  {
    code: '12019',
    message: 'Problems with field Industry',
  },
  {
    code: '12020',
    message: 'Wrong option for chosen territorial subdivision',
  },
  {
    code: '12021',
    message: 'No correct user agreement in compliance',
  },
  {
    code: '13020',
    message: 'One of offense in compliance',
  },
  {
    code: '12022',
    message: 'Problems with field File',
  },
  {
    code: '12036',
    message: 'Problems with field Date',
  },
  // 03 - логические ошибки сервиса
  {
    code: '03010',
    message: 'This Email was not found',
  },
  {
    code: '03002',
    message: 'This Email already exists',
  },
  {
    code: '03009',
    message: 'Exceeded the limit of revocation application',
  },
  {
    code: '13022',
    message: 'This emal is already registered for the webinar',
  },
  // 04
  {
    code: '04001',
    message: 'Something is wrong with your request',
  },
  {
    code: '04002',
    message: 'Something went wrong',
  },
  {
    code: '04013',
    message: 'Something went wrong',
  },
  {
    code: '14001',
    message: 'Something went wrong',
  },
  {
    code: '04005',
    message: 'Authorization error',
  },
  {
    code: '04006',
    message: 'Authorization error',
  },
  {
    code: '04014',
    message: 'Something is wrong with your request',
  },
];

const formCodeToMessageError = (resultCode: string) => {
  const itemWithThisCodeRu = MockFormErrorsCodesAndMessagesRu.find(
    (item) => item.code === resultCode,
  );
  if (itemWithThisCodeRu) return itemWithThisCodeRu.message;

  return 'Неизвестная ошибка приложения';
};

const resAdapter = async (res: Response): Promise<FormAfterAdapter> => {
  const baseError: FormAfterAdapter = {
    formStatus: 'error',
    formMessage: 'Неизвестная ошибка приложения',
    resultCode: '01001',
  };
  if (res.ok) {
    const resJson: ResFormDataOnSuccess = await res.json();
    const formMessage = formCodeToMessageSuccess(resJson.resultCode);
    return {
      formStatus: 'success',
      formMessage,
      resData: resJson.data,
      resultCode: resJson.resultCode,
    };
  } else {
    try {
      const resJson: ResFormDataOnError = await res.json();
      const formMessage = formCodeToMessageError(resJson.resultCode);
      return {
        formStatus: 'error',
        formMessage,
        resultCode: resJson.resultCode,
      };
    } catch (error) {
      return baseError;
    }
  }
};

type FetchMethod = 'PUT' | 'POST';

/**
 *  Тип without используется только в компоненте лайка в поисках
 */

interface SubmitForm {
  backendPatchInput: BackendPatchInput;
  dataFromFormInputs: DataFromFormInputs;
  allFormSentFormType: AnalyticsFormType | 'without';
  allFormSentIsPopup?: boolean;
  fetchMethod?: FetchMethod;
  sendUserMetaData?: boolean;
  onSuccess?: (resData: ResFormDataOnSuccess['data']) => void;
  onError?: () => void;
  formData?: FormData;
}

/**
 *
 * @description Универсальный хук для отправки данных на формах
 *
 *
 * Использование:
 * @example
 * const { submitForm, formStatus, setFormStatus, formMessage } =
    useSubmitForm();
 *
 * @description and in on onSubmit:
 *
 * @example
 * await submitForm({
      backendPatchInput: 'SOME',
      dataFromFormInputs: data,
      onSuccess: () => {
        sendDataToDataLayer({
          dataLayer: {
            event: 'SOME',
            event_category: 'SOME',
            event_action: 'SOME',
            event_label: 'SOME',
          },
        });
      },
    });
 *
 * @description backendPatchInput - всегда начинаться должен с "/" - к примеру "/forms/v1/order"
 *
 * @description для типов форм можно использовать DataFromFormInputs
 * @example
 * import { DataFromFormInputs } from 'src/types';
 */
export const useSubmitForm = () => {
  const [formStatus, setFormStatus] = useState<FormStatus>('idle');
  const [resultCode, setResultCode] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const metaDataForForm = useGetUserMetaData();
  const searchParams = useSearchParams();
  const { allFormSent } = useAnalytics();

  const shadowQuery = getStringFromRouterQuery(searchParams.get('shadow'));

  const submitForm = async ({
    backendPatchInput,
    dataFromFormInputs,
    fetchMethod = 'POST',
    sendUserMetaData = true,
    allFormSentFormType,
    allFormSentIsPopup,
    onSuccess,
    onError,
    formData,
  }: SubmitForm) => {
    setFormStatus('sending');

    let dataToSubmit = dataFromFormInputs;

    //Из инпута номер приходит с маской +7(000)000-00-00, это приведение его к единому виду +70000000000
    for (const key in dataToSubmit) {
      const data = dataToSubmit[key];

      if (data && typeof data === 'string') {
        if (data.startsWith('+7'))
          dataToSubmit[key] = dataToSubmit[key]
            ?.toString()
            .replace(/[-()\s]/gm, '');
      }
    }

    if (formData) {
      formData.append('gaClientID', metaDataForForm.gaClientID);
      formData.append('yaClientID', metaDataForForm.yaClientID);
      formData.append('retainURL', metaDataForForm.retainURL);
      formData.append('originalUTM', metaDataForForm.originalUTM);
      formData.append('shadowInput', shadowQuery);
    }

    if (sendUserMetaData)
      dataToSubmit = {
        ...metaDataForForm,
        ...dataToSubmit,
      };

    dataToSubmit = {
      ...dataToSubmit,
      shadowInput: shadowQuery,
    };

    const sendSentry = (res: Response, formMessage: string, waf?: boolean) => {
      const transactionName = waf ? 'waf' : `${backendPatchInput}`;
      Sentry.withScope(function (scope) {
        scope.setTransactionName(transactionName);
        Sentry.setTag('formError', `${backendPatchInput}`);
        const body = formData
          ? Object.fromEntries(formData.entries())
          : dataToSubmit;
        body.jsonBody = JSON.stringify(body);
        body.formStatus = `${res.status} - ${formMessage}`;
        Sentry.setContext('form_body', body);
        scope.setFingerprint([backendPatchInput]);
        Sentry.captureException(
          new Error(
            `Form sending Error - ${backendPatchInput} - ${formMessage}`,
          ),
          scope,
        );
      });
    };

    const res = await fetch(`${backendHost}${backendPatchInput}`, {
      method: fetchMethod,
      headers: !formData
        ? {
            'Content-Type': 'application/json;charset=utf-8',
          }
        : {},
      body: formData ? formData : JSON.stringify(dataToSubmit),
    }).catch((error: Response) => {
      setFormStatus('error');
      setFormMessage('Неизвестная ошибка приложения');
      setResultCode('waf');
      sendSentry(error, 'Неизвестная ошибка приложения - waf', true);
      onError && onError();
    });

    if (res) {
      const { formStatus, formMessage, resData, resultCode } = await resAdapter(
        res,
      );

      if (!res.ok) {
        sendSentry(res, formMessage);
      }

      if (formStatus === 'success' && allFormSentFormType !== 'without') {
        allFormSent(
          allFormSentFormType,
          !!dataToSubmit.subscribeForMailing,
          allFormSentIsPopup,
        );
      }

      if (formStatus === 'success' && onSuccess) onSuccess(resData);
      if (formStatus === 'error' && onError) onError();

      setFormMessage(formMessage);
      setFormStatus(formStatus);
      setResultCode(resultCode);
    }
  };

  return { submitForm, formStatus, setFormStatus, formMessage, resultCode };
};
