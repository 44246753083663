import { PromoTag, PromoTagProps } from '@snack-uikit/promo-tag';
import { QuestionTooltip } from '@snack-uikit/tooltip';
import cn from 'classnames';

import s from './TagSpecial.module.scss';

export type TagSpecialProps = {
  className?: string;
  appearance?: PromoTagProps['appearance'];
  label?: string;
  tooltip?: string;
};

export function TagSpecial(props: TagSpecialProps) {
  const { className, appearance, label, tooltip } = props;

  const tooltipNode = tooltip ? (
    <QuestionTooltip
      data-appearance={appearance}
      className={cn(s.tooltip, className)}
      tip={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
    />
  ) : undefined;

  return (
    <PromoTag
      className={cn(s.wrapper, className)}
      size="xs"
      color="decor"
      appearance={appearance}
      text={label}
      afterContent={tooltipNode}
    />
  );
}
